<!--
 * @Author: mulingyuer
 * @Date: 2021-04-14 14:44:56
 * @LastEditTime: 2021-04-25 17:47:01
 * @LastEditors: mulingyuer
 * @Description: 奖品设置
 * @FilePath: \saas-admin-vue\src\modules\lottery\views\prize-config.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="prize-config">
    <div class="head">
      <PrizeAlert />
    </div>
    <div class="content" v-loading="loading">
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe>
        <el-table-column prop="awards_position" label="奖项位置" minWidth="100">
          <template slot-scope="{row}">奖品{{row.awards_position}}</template>
        </el-table-column>
        <el-table-column prop="awards_name" label="奖项名称" minWidth="180">
          <template slot-scope="{row}">
            <el-input v-model="row.awards_name" placeholder="请输入奖项名称"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="prize_icon" label="奖品图片" minWidth="150">
          <template slot-scope="{row,$index}">
            <div class="prize-icon" @click="onEditPrizeIcon($index)">
              <img :src="fixImageUrl(row.prize_icon)" alt="奖品图片">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="prize_name" label="奖品名称" minWidth="180">
          <template slot-scope="{row}">
            <el-input v-model="row.prize_name" placeholder="请输入奖品名称"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="prize_type" label="奖品类型" minWidth="180">
          <template slot-scope="{row,$index}">
            <el-select v-model="row.prize_type" placeholder="请选择奖品类型" @change="onPrizeTypeChange(row,$index)">
              <el-option label="谢谢参与" :value="0"></el-option>
              <el-option label="实物" :value="1"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="prize_total_count" label="总数量" minWidth="170">
          <template slot-scope="{row}">
            <el-input v-if="row.prize_type===1" v-model="row.prize_total_count" placeholder="请输入总数量">
            </el-input>
            <span v-else>
              <span v-if="row.prize_type===0">--</span>
              <span v-else>{{row.prize_remaining_count|prizePlaceholder}}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="prize_remaining_count" label="剩余数" minWidth="100">
          <template slot-scope="{row}">
            <span v-if="row.prize_type===0">--</span>
            <span v-else>{{row.prize_remaining_count|prizePlaceholder}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="probability" label="中奖概率" minWidth="170">
          <template slot-scope="{row}">
            <el-input v-model="row.probability" placeholder="请输入中奖概率"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <!-- 悬浮按钮 -->
      <fixed-action-bar>
        <el-button v-if="!loading" type="primary" size="medium" :loading="saveLoading" @click="onSave()">保存
        </el-button>
        <el-button size="medium" @click="onCancel">取消</el-button>
      </fixed-action-bar>
    </div>
    <!-- 媒体库弹窗 -->
    <MediaDialog v-model="openMediaDialog" @select="onSelect" @input="onClose" />
  </div>
</template>
<script>
import PrizeAlert from "../components/prize-config/Alert";
import MediaDialog from "@/modules/common/components/MediaSelector";
import FixedActionBar from "@/base/layout/FixedActionBar";
import { prizeList, prizeSave } from "../api/prize-config";
export default {
  props: {
    //抽奖数据id
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false, //加载中
      tbody: [], //表格数据
      openMediaDialog: false, //是否显示媒体库弹窗
      editditPrizeIconIndex: "", //要修改的奖品图片数据下标
      saveLoading: false, //保存中
    };
  },
  methods: {
    //获取奖品列表
    getPrizeList() {
      this.loading = true;
      const postData = { id: this.id };
      prizeList(postData)
        .then((res) => {
          const { data } = res;
          this.tbody = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //修改奖品图片
    onEditPrizeIcon(index) {
      this.editditPrizeIconIndex = index;
      this.openMediaDialog = true;
    },
    //媒体库确认
    onSelect(data) {
      if (data.length === 1) {
        const url = data[0].url;
        this.tbody[this.editditPrizeIconIndex].prize_icon = url;
      }
    },
    //媒体库取消
    onClose() {
      this.editditPrizeIconIndex = "";
    },
    //取消
    onCancel() {
      this.$router.push({ name: "LotteryList" });
    },
    //保存
    onSave() {
      this.saveLoading = true;
      const postData = {
        id: this.id,
        prizes: JSON.stringify(this.tbody),
      };
      prizeSave(postData)
        .then((res) => {
          const { msg } = res;
          this.$message.success(msg);
          this.saveLoading = false;
          this.$router.push({ name: "LotteryList" });
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    //奖品类型发生变化
    onPrizeTypeChange(row, index) {
      if (row.prize_type === 1) {
        const defaultCount = 10;
        if (row.prize_total_count === -1) {
          this.tbody[index].prize_total_count = defaultCount;
        }
      }
    },
  },
  filters: {
    prizePlaceholder(value) {
      if ((!value && value !== 0) || value === -1) return "--";
      return value;
    },
  },
  created() {
    //获取奖品列表
    this.getPrizeList();
  },
  components: {
    PrizeAlert,
    MediaDialog,
    FixedActionBar,
  },
};
</script>
<style lang="scss" scoped>
.prize-config {
  .head {
    margin-bottom: 24px;
  }
  .content {
    min-height: 400px;
    padding-bottom: 20px;
    .el-input,
    .el-select {
      max-width: 150px;
    }
    .prize-icon {
      width: 80px;
      height: 50px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        vertical-align: top;
        object-fit: contain;
      }
    }
  }
}
</style>