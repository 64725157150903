var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prize-config"},[_c('div',{staticClass:"head"},[_c('PrizeAlert')],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content"},[_c('el-table',{staticClass:"thead-light",staticStyle:{"width":"100%"},attrs:{"data":_vm.tbody,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"awards_position","label":"奖项位置","minWidth":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("奖品"+_vm._s(row.awards_position))]}}])}),_c('el-table-column',{attrs:{"prop":"awards_name","label":"奖项名称","minWidth":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"请输入奖项名称"},model:{value:(row.awards_name),callback:function ($$v) {_vm.$set(row, "awards_name", $$v)},expression:"row.awards_name"}})]}}])}),_c('el-table-column',{attrs:{"prop":"prize_icon","label":"奖品图片","minWidth":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('div',{staticClass:"prize-icon",on:{"click":function($event){return _vm.onEditPrizeIcon($index)}}},[_c('img',{attrs:{"src":_vm.fixImageUrl(row.prize_icon),"alt":"奖品图片"}})])]}}])}),_c('el-table-column',{attrs:{"prop":"prize_name","label":"奖品名称","minWidth":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"请输入奖品名称"},model:{value:(row.prize_name),callback:function ($$v) {_vm.$set(row, "prize_name", $$v)},expression:"row.prize_name"}})]}}])}),_c('el-table-column',{attrs:{"prop":"prize_type","label":"奖品类型","minWidth":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-select',{attrs:{"placeholder":"请选择奖品类型"},on:{"change":function($event){return _vm.onPrizeTypeChange(row,$index)}},model:{value:(row.prize_type),callback:function ($$v) {_vm.$set(row, "prize_type", $$v)},expression:"row.prize_type"}},[_c('el-option',{attrs:{"label":"谢谢参与","value":0}}),_c('el-option',{attrs:{"label":"实物","value":1}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"prize_total_count","label":"总数量","minWidth":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.prize_type===1)?_c('el-input',{attrs:{"placeholder":"请输入总数量"},model:{value:(row.prize_total_count),callback:function ($$v) {_vm.$set(row, "prize_total_count", $$v)},expression:"row.prize_total_count"}}):_c('span',[(row.prize_type===0)?_c('span',[_vm._v("--")]):_c('span',[_vm._v(_vm._s(_vm._f("prizePlaceholder")(row.prize_remaining_count)))])])]}}])}),_c('el-table-column',{attrs:{"prop":"prize_remaining_count","label":"剩余数","minWidth":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.prize_type===0)?_c('span',[_vm._v("--")]):_c('span',[_vm._v(_vm._s(_vm._f("prizePlaceholder")(row.prize_remaining_count)))])]}}])}),_c('el-table-column',{attrs:{"prop":"probability","label":"中奖概率","minWidth":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"请输入中奖概率"},model:{value:(row.probability),callback:function ($$v) {_vm.$set(row, "probability", $$v)},expression:"row.probability"}})]}}])})],1),_c('fixed-action-bar',[(!_vm.loading)?_c('el-button',{attrs:{"type":"primary","size":"medium","loading":_vm.saveLoading},on:{"click":function($event){return _vm.onSave()}}},[_vm._v("保存 ")]):_vm._e(),_c('el-button',{attrs:{"size":"medium"},on:{"click":_vm.onCancel}},[_vm._v("取消")])],1)],1),_c('MediaDialog',{on:{"select":_vm.onSelect,"input":_vm.onClose},model:{value:(_vm.openMediaDialog),callback:function ($$v) {_vm.openMediaDialog=$$v},expression:"openMediaDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }