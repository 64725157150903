/*
 * @Author: mulingyuer
 * @Date: 2021-04-14 16:36:48
 * @LastEditTime: 2021-04-14 17:23:16
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\lottery\api\prize-config.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';

// 奖品列表
export const prizeList = data => {
  return api({
    url: '/admin/lottery/lottery/getPrizes',
    method: 'post',
    data
  });
};


// 奖品列表保存 
export const prizeSave = data => {
  return api({
    url: '/admin/lottery/lottery/savePrizes',
    method: 'post',
    data
  });
};
